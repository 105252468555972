
import { objectDifference } from "@/helpers";
import {
  atlasfileMapActions,
  atlasfileMapGetters,
  atlasfileMapMutations,
  atlasfileMapState
} from "@/store/modules/atlasfile";
import * as types from "@/store/mutation-types";
import { userRoleArr } from "@/types";
import { capitalize } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "edit-atlas-file",
  components: {
    SelectSearchUsers: () =>
      import("@/components/FormFields/SelectSearchUsers.vue"),
    SelectSearchAgencies: () =>
      import("@/components/FormFields/SelectSearchAgencies.vue"),
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  props: {
    categoryOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      error: "",
      atlasFileCategoryOptions: this.categoryOptions,
      userRoleOptions: userRoleArr.map((role: string) => ({
        label: capitalize(role),
        value: role
      }))
    };
  },
  methods: {
    ...atlasfileMapMutations({
      editField: types.SET_EDIT_FIELD,
      setEdit: types.SET_EDIT
    }),
    ...atlasfileMapActions(["updateAtlasfile"]),
    async updateAtlasFile(): Promise<void> {
      try {
        await this.updateAtlasfile({
          id: this.editing._id,
          update: this.updatedFields
        });
        this.setEdit({} as any);
        this.$emit("close");
        this.$appNotifySuccess("Document update successful");
      } catch (error) {
        this.error = error as string;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...atlasfileMapState(["editing", "makingApiRequest"]),
    ...atlasfileMapGetters(["getAtlasfileById"]),
    updatedFields(): any {
      const original = this.getAtlasfileById(this.editing._id);
      const edited = this.editing;
      return objectDifference(edited, original);
    }
  }
});
