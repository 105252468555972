var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],staticClass:"mt-20"},[_c('custom-alert',{attrs:{"title":"An Error Occurred","message":_vm.error,"show":!!_vm.error,"type":"danger"},on:{"dismiss":function($event){_vm.error = ''}}}),_c('div',[_c('TextField',{ref:"field_countyName",attrs:{"label":"Filename","placeholder":"Dingy dango","value":_vm.editing.name,"required":true,"fixedLabels":true},on:{"input":function($event){return _vm.editField({ key: 'name', value: $event })}}}),_c('SelectField',{attrs:{"label":_vm.__getText('fields', 'category'),"options":_vm.atlasFileCategoryOptions,"selected":_vm.editing.category,"required":true,"fixedLabels":true,"fieldGroupClass":""},on:{"change":function($event){return _vm.editField({ key: 'category', value: $event })}}}),_c('el-checkbox',{staticClass:"block mt-8",attrs:{"checked":_vm.editing.isPublic},on:{"change":function($event){return _vm.editField({ key: 'isPublic', value: $event })}}},[_vm._v(_vm._s(_vm.__getTextUcFirst("fields", "isPublicLabel"))+" ")])],1),(!_vm.editing.isPublic)?_c('div',[_c('p',{staticClass:"text-md mb-8"},[_vm._v(" Access Control Config: ")]),_c('div',{staticClass:"flex w-full items-start justify-between mb-8 space-x-2"},[_c('SelectSearchUsers',{attrs:{"label":"Allowed Users","selected":_vm.editing &&
          _vm.editing.documentAccessConfig &&
          _vm.editing.documentAccessConfig.allowedUsers
            ? _vm.editing.documentAccessConfig.allowedUsers
            : [],"placeholder":"Search and select allowed users","required":true,"multiple":true,"fixedLabels":true},on:{"change":function($event){return _vm.editField({
            key: 'documentAccessConfig.allowedUsers',
            value: $event
          })}}}),_c('SelectSearchAgencies',{attrs:{"label":"Allowed Agencies","value":_vm.editing &&
          _vm.editing.documentAccessConfig &&
          _vm.editing.documentAccessConfig.allowedAgencies
            ? _vm.editing.documentAccessConfig.allowedAgencies
            : [],"placeholder":"Select agencies","required":true,"multiple":true,"fixedLabels":true},on:{"change":function($event){return _vm.editField({
            key: 'documentAccessConfig.allowedAgencies',
            value: $event
          })}}}),_c('SelectField',{attrs:{"label":"Allowed user roles","options":_vm.userRoleOptions,"selected":_vm.editing &&
          _vm.editing.documentAccessConfig &&
          _vm.editing.documentAccessConfig.allowedRoles
            ? _vm.editing.documentAccessConfig.allowedRoles
            : [],"required":true,"multiple":true,"fixedLabels":true,"fieldGroupClass":""},on:{"change":function($event){return _vm.editField({
            key: 'documentAccessConfig.allowedRoles',
            value: $event
          })}}})],1)]):_vm._e(),_c('div',{staticClass:"flex justify-end mt-4"},[(Object.keys(_vm.updatedFields).length > 0)?_c('IAButton',{attrs:{"click":_vm.updateAtlasFile,"title":"Update Document","type":"primary","size":"medium"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }